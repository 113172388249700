<template>
  <div>
    <router-view v-if="marketList"> </router-view>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  mounted() {
    if (
      this.locationEventManager &&
      this.locationEventManager?.channels?.locationChanged
    ) {
      this.locationEventManager.listener.on(
        this.locationEventManager.channels.locationChanged,
        (data) => {
          this.setLocation(data.data);
        }
      );
    }
    this.fetchMarketListData();
  },
  computed: {
    ...mapGetters({
      modules: "availableModules",
    }),
    ...mapState({
      currentPosition: (state) => state.location.currentPosition,
      newStore: (state) => state["home"].newStores,
      marketList: (state) => state["home"].marketList,
    }),
    getCurrentLocation() {
      return this.currentPosition?.data?.City;
    },
    locationEventManager() {
      return this.modules["location"]?.eventManager?.location;
    },
  },
  methods: {
    ...mapActions({
      setLocation: "home/setLocation",
      fetchMarketListData: "home/fetchMarketList",
    }),
  },
};
</script>
